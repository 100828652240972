import React from "react";

export default function Volunteer() {
	return (
		<svg
			width="17"
			height="17"
			viewBox="0 0 17 17"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M5.56543 14.1806V8.16394H6.6321C6.70987 8.16394 6.78765 8.17239 6.86543 8.18928C6.94321 8.20616 7.02099 8.2255 7.09876 8.24728L11.7154 9.96394C11.871 10.0195 11.9961 10.1195 12.0908 10.2639C12.1854 10.4084 12.2325 10.5584 12.2321 10.7139C12.2321 10.9473 12.1514 11.1362 11.9901 11.2806C11.8288 11.4251 11.6428 11.4973 11.4321 11.4973H9.6821C9.62654 11.4973 9.58499 11.4946 9.55743 11.4893C9.52987 11.4839 9.49365 11.4699 9.44876 11.4473L8.3821 11.0306L8.16543 11.6806L9.44876 12.1306C9.47099 12.1417 9.50432 12.1502 9.54876 12.1559C9.59321 12.1617 9.6321 12.1644 9.66543 12.1639H14.2321C14.5877 12.1639 14.8988 12.2917 15.1654 12.5473C15.4321 12.8028 15.5654 13.1195 15.5654 13.4973L10.2488 15.4973L5.56543 14.1806ZM1.56543 15.4973V8.16394H4.2321V15.4973H1.56543ZM11.5654 9.49728L8.79876 6.79728C8.45432 6.46394 8.16276 6.09461 7.9241 5.68928C7.68543 5.28394 7.56587 4.84216 7.56543 4.36394C7.56543 3.75283 7.77943 3.2335 8.20743 2.80594C8.63543 2.37839 9.15476 2.16439 9.76543 2.16394C10.121 2.16394 10.4543 2.23905 10.7654 2.38928C11.0765 2.5395 11.3432 2.74216 11.5654 2.99728C11.7877 2.74172 12.0543 2.53883 12.3654 2.38861C12.6765 2.23839 13.0099 2.1635 13.3654 2.16394C13.9765 2.16394 14.4961 2.37794 14.9241 2.80594C15.3521 3.23394 15.5659 3.75328 15.5654 4.36394C15.5654 4.84172 15.4488 5.2835 15.2154 5.68928C14.9821 6.09505 14.6932 6.46439 14.3488 6.79728L11.5654 9.49728Z"
				fill="currentColor"
			/>
		</svg>
	);
}
