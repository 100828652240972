import React from "react";

export default function CloseIcon() {
	return (
		<svg
			width="34"
			height="34"
			viewBox="0 0 34 34"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M19.2546 17L25.5632 10.6914C25.8626 10.3925 26.0311 9.98691 26.0314 9.56387C26.0318 9.14082 25.8641 8.73495 25.5652 8.43554C25.2663 8.13614 24.8608 7.96772 24.4377 7.96735C24.0147 7.96698 23.6088 8.13467 23.3094 8.43355L17.0008 14.7421L10.6922 8.43355C10.3928 8.13415 9.98673 7.96594 9.56331 7.96594C9.13989 7.96594 8.73381 8.13415 8.43441 8.43355C8.135 8.73296 7.9668 9.13904 7.9668 9.56246C7.9668 9.98588 8.135 10.392 8.43441 10.6914L14.743 17L8.43441 23.3085C8.135 23.608 7.9668 24.014 7.9668 24.4375C7.9668 24.8609 8.135 25.267 8.43441 25.5664C8.73381 25.8658 9.13989 26.034 9.56331 26.034C9.98673 26.034 10.3928 25.8658 10.6922 25.5664L17.0008 19.2578L23.3094 25.5664C23.6088 25.8658 24.0149 26.034 24.4383 26.034C24.8617 26.034 25.2678 25.8658 25.5672 25.5664C25.8666 25.267 26.0348 24.8609 26.0348 24.4375C26.0348 24.014 25.8666 23.608 25.5672 23.3085L19.2546 17Z"
				fill="currentColor"
			/>
		</svg>
	);
}
