import React from "react";

export default function FunFact() {
	return (
		<svg
			width="17"
			height="17"
			viewBox="0 0 17 17"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<g clip-path="url(#clip0_331_175)">
				<path
					d="M8.89848 1.15851V1.82517L8.38514 1.39851L6.07181 4.15851L8.23181 5.49184V3.93184L8.08514 3.82518L8.89848 2.86517V15.8252H10.2318V9.31851L11.5185 8.03184C13.2318 8.29851 14.9851 7.39851 16.0051 6.37851L15.0585 5.43184C14.2985 6.19851 12.6985 6.89851 11.5651 6.66518L10.2318 5.31851V1.15851M12.2318 3.15851C11.8782 3.15851 11.5391 3.29898 11.289 3.54903C11.039 3.79908 10.8985 4.13822 10.8985 4.49184C10.8985 4.84546 11.039 5.1846 11.289 5.43465C11.5391 5.6847 11.8782 5.82518 12.2318 5.82518C12.5854 5.82518 12.9246 5.6847 13.1746 5.43465C13.4247 5.1846 13.5651 4.84546 13.5651 4.49184C13.5651 4.13822 13.4247 3.79908 13.1746 3.54903C12.9246 3.29898 12.5854 3.15851 12.2318 3.15851ZM8.23181 5.66518L5.63181 8.26518C4.39848 9.49184 3.77181 10.3918 2.93848 12.5985L4.19181 13.0652C4.85848 11.2918 5.33848 10.5852 6.14514 9.71851L7.04514 10.6185L3.74514 13.9185L4.69181 14.8652L8.23181 11.3185V5.66518Z"
					fill="currentColor"
				/>
			</g>
			<defs>
				<clipPath id="clip0_331_175">
					<rect
						width="16"
						height="16"
						fill="white"
						transform="translate(0.898438 0.491821)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
}
