import React from "react";

export default function Instagram() {
	return (
		<svg
			width="31"
			height="32"
			viewBox="0 0 31 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M10.0747 3.18329H20.9247C25.058 3.18329 28.4163 6.54162 28.4163 10.675V21.525C28.4163 23.5119 27.627 25.4174 26.2221 26.8224C24.8171 28.2273 22.9116 29.0166 20.9247 29.0166H10.0747C5.94134 29.0166 2.58301 25.6583 2.58301 21.525V10.675C2.58301 8.68804 3.37231 6.78251 4.77727 5.37755C6.18223 3.97259 8.08776 3.18329 10.0747 3.18329ZM9.81634 5.76662C8.58308 5.76662 7.40034 6.25653 6.52829 7.12858C5.65625 8.00062 5.16634 9.18336 5.16634 10.4166V21.7833C5.16634 24.3537 7.24592 26.4333 9.81634 26.4333H21.183C22.4163 26.4333 23.599 25.9434 24.4711 25.0713C25.3431 24.1993 25.833 23.0165 25.833 21.7833V10.4166C25.833 7.8462 23.7534 5.76662 21.183 5.76662H9.81634ZM22.2809 7.70412C22.7091 7.70412 23.1198 7.87423 23.4226 8.17702C23.7254 8.47982 23.8955 8.89049 23.8955 9.31871C23.8955 9.74692 23.7254 10.1576 23.4226 10.4604C23.1198 10.7632 22.7091 10.9333 22.2809 10.9333C21.8527 10.9333 21.442 10.7632 21.1392 10.4604C20.8364 10.1576 20.6663 9.74692 20.6663 9.31871C20.6663 8.89049 20.8364 8.47982 21.1392 8.17702C21.442 7.87423 21.8527 7.70412 22.2809 7.70412ZM15.4997 9.64162C17.2125 9.64162 18.8552 10.3221 20.0664 11.5332C21.2776 12.7444 21.958 14.3871 21.958 16.1C21.958 17.8128 21.2776 19.4555 20.0664 20.6667C18.8552 21.8779 17.2125 22.5583 15.4997 22.5583C13.7868 22.5583 12.1441 21.8779 10.9329 20.6667C9.72177 19.4555 9.04134 17.8128 9.04134 16.1C9.04134 14.3871 9.72177 12.7444 10.9329 11.5332C12.1441 10.3221 13.7868 9.64162 15.4997 9.64162ZM15.4997 12.225C14.472 12.225 13.4863 12.6332 12.7596 13.3599C12.0329 14.0866 11.6247 15.0722 11.6247 16.1C11.6247 17.1277 12.0329 18.1133 12.7596 18.84C13.4863 19.5667 14.472 19.975 15.4997 19.975C16.5274 19.975 17.513 19.5667 18.2397 18.84C18.9664 18.1133 19.3747 17.1277 19.3747 16.1C19.3747 15.0722 18.9664 14.0866 18.2397 13.3599C17.513 12.6332 16.5274 12.225 15.4997 12.225Z"
				fill="currentColor"
			/>
		</svg>
	);
}
