import React from "react";

export default function Home() {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M12.4993 18.3875V14.2208C12.4993 13.5577 12.236 12.9219 11.7671 12.453C11.2983 11.9842 10.6624 11.7208 9.99935 11.7208C9.33631 11.7208 8.70042 11.9842 8.23158 12.453C7.76274 12.9219 7.49935 13.5577 7.49935 14.2208V18.3875H3.33268C2.89065 18.3875 2.46673 18.2119 2.15417 17.8993C1.84161 17.5867 1.66602 17.1628 1.66602 16.7208V7.66412C1.66601 7.37631 1.74054 7.0934 1.88234 6.84295C2.02415 6.5925 2.22839 6.38303 2.47518 6.23495L9.14185 2.23495C9.40088 2.07953 9.69727 1.99744 9.99935 1.99744C10.3014 1.99744 10.5978 2.07953 10.8568 2.23495L17.5235 6.23495C17.7703 6.38303 17.9746 6.5925 18.1164 6.84295C18.2582 7.0934 18.3327 7.37631 18.3327 7.66412V16.7208C18.3327 17.1628 18.1571 17.5867 17.8445 17.8993C17.532 18.2119 17.108 18.3875 16.666 18.3875H12.4993Z"
				fill="currentColor"
			/>
		</svg>
	);
}
