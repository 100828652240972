import React from "react";

export default function Github() {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 31 31"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M15.5007 2.58325C13.8044 2.58325 12.1248 2.91735 10.5577 3.56647C8.99053 4.2156 7.56661 5.16703 6.36719 6.36646C3.94484 8.7888 2.58398 12.0742 2.58398 15.4999C2.58398 21.2091 6.29107 26.0528 11.419 27.7708C12.0648 27.8741 12.2715 27.4737 12.2715 27.1249V24.942C8.69357 25.717 7.93148 23.2112 7.93148 23.2112C7.33732 21.7128 6.49773 21.3124 6.49773 21.3124C5.32232 20.5116 6.58815 20.5374 6.58815 20.5374C7.87982 20.6278 8.5644 21.8678 8.5644 21.8678C9.68815 23.8312 11.5869 23.2499 12.3232 22.9399C12.4394 22.1003 12.7752 21.532 13.1369 21.2091C10.2694 20.8862 7.25982 19.7753 7.25982 14.8541C7.25982 13.4203 7.75065 12.2708 8.59023 11.3537C8.46107 11.0308 8.00898 9.68742 8.7194 7.94367C8.7194 7.94367 9.8044 7.59492 12.2715 9.26117C13.2919 8.977 14.4027 8.83492 15.5007 8.83492C16.5986 8.83492 17.7094 8.977 18.7298 9.26117C21.1969 7.59492 22.2819 7.94367 22.2819 7.94367C22.9923 9.68742 22.5402 11.0308 22.4111 11.3537C23.2507 12.2708 23.7415 13.4203 23.7415 14.8541C23.7415 19.7883 20.719 20.8733 17.8386 21.1962C18.3036 21.5966 18.7298 22.3845 18.7298 23.5858V27.1249C18.7298 27.4737 18.9365 27.887 19.5952 27.7708C24.7232 26.0399 28.4173 21.2091 28.4173 15.4999C28.4173 13.8037 28.0832 12.124 27.4341 10.5569C26.785 8.9898 25.8335 7.56588 24.6341 6.36646C23.4347 5.16703 22.0108 4.2156 20.4436 3.56647C18.8765 2.91735 17.1969 2.58325 15.5007 2.58325Z"
				fill="currentColor"
			/>
		</svg>
	);
}
