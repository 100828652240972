import React from "react";

export default function Medium() {
	return (
		<svg
			width="31"
			height="32"
			viewBox="0 0 31 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M17.3216 15.809C17.3216 20.2653 13.732 23.8781 9.30678 23.8781C4.88024 23.8781 1.29199 20.2666 1.29199 15.809C1.29199 11.3528 4.88024 7.73999 9.30678 7.73999C13.732 7.73999 17.3216 11.3528 17.3216 15.809ZM26.1114 15.809C26.1114 20.0044 24.316 23.404 22.1046 23.404C19.8907 23.404 18.0966 20.0031 18.0966 15.809C18.0966 11.615 19.892 8.21403 22.1046 8.21403C24.3172 8.21403 26.1114 11.615 26.1114 15.809ZM29.7074 15.809C29.7074 19.5678 29.0757 22.6148 28.2982 22.6148C27.5193 22.6148 26.8889 19.5665 26.8889 15.809C26.8889 12.0503 27.5193 9.00324 28.2982 9.00324C29.077 9.00324 29.7087 12.0516 29.7087 15.8103"
				fill="currentColor"
			/>
		</svg>
	);
}
